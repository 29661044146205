#root {
 background-color: var(--mui-palette-background-default);
}

@property --shine-offset {
 syntax: '<percentage>';
 inherits: false;
 initial-value: -15%;
}

@keyframes shine {
 0% {
  --shine-offset: -15%;
 }

 10% {
  --shine-offset: 100%;
 }

 100% {
  --shine-offset: 100%;
 }
}
